import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import {  createTheme, ThemeProvider, styled } from '@mui/material/styles';
import "./App.css";


const theme = createTheme({
  palette: {
    primary: {
      main: "#004b9b"
    },
  },
})

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ThemeProvider theme = {theme}>
    <App />
  </ThemeProvider>
  
);

